<template>
	<div>
		<back-link></back-link>
		<div v-html="page" ></div>     
	</div>
</template>

<script>
import BackLink from '@/components/BackLink';
export default {
	components:{
		BackLink
	},
	props:['htmlfile'],
	data(){
		return {
			page: null
		}
	},
	async mounted(){
		const blogFileName = this.htmlfile;
		await this.axios.get(process.env.VUE_APP_SERVER_URL + "/blog/"+ this.$store.state.menuPath + "/" + blogFileName)
		.then(response => {
			const content  = response.data;
			this.page = content.content;
		}).catch(error => alert(error));
	}
}
</script>