<template>
	<div>
		<button @click="back">Back</button>
	</div>
</template>
<script>
export default {
	data(){
		return {

		}
	},
	methods:{
		back(){
			this.$store.commit('mainComponentName', 'BlogList');
            this.$store.commit('vmenuActive', false);
            const prevSelectedHmenuItem = this.$store.state.selectedHmenuItem;
            if (prevSelectedHmenuItem !== null)
				prevSelectedHmenuItem.selected = false;
		}
	}
}
</script>
<style scoped>
</style>